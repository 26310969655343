export const NEW_PATH = 'new';
export const EDIT_PATH = 'edit';

export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const OAUTH_CALLBACK_PATH = '/oauth/callback';

export const ADMINISTRATION_PATH = '/administration';

export const COMPANY_SETTINGS_PATH = 'company-settings';

export const OFFICES_PATH = 'offices';
export const OFFICE_PATH = ':officeId';
export const EDIT_OFFICE_PATH = `${OFFICE_PATH}/${EDIT_PATH}`;

export const USERS_PATH = 'users';

export const EMPLOYEES_PATH = '/employees';
export const EMPLOYEE_PATH = ':employeeId';
export const EMPLOYEE_ACCOUNT_PATH = 'account';
export const DEPENDANTS_PATH = 'dependants';
export const DEPENDANT_PATH = ':dependantId';
export const GIFTS_PATH = 'gifts';
export const ACCEPT_ADDRESS_PATH = 'accept-address';

export const DELIVERIES_PATH = '/deliveries';
export const DELIVERY_PATH = ':deliveryId';
export const DETAILS_PATH = 'details';
export const OVERVIEW_PATH = 'overview';
export const HISTORY_PATH = 'history';
