import { generatePath, Navigate, Route, Routes } from 'react-router-dom';
import { OauthCallback, PrivateOutlet } from '@chocolate-soup-inc/cs-frontend-components';
import {
  CompanySettings,
  Offices,
  OfficeCreateForm,
  OfficeUpdateForm,
  Users,
  UserCreateForm,
  EmployeeAccount,
  EmployeeDependants,
  EmployeeManagement as EmployeeManagementPage,
  EmployeeDependantCreateForm,
  EmployeeDependantUpdateForm,
  EmployeeCreateForm,
  EmployeeUpdateForm,
  Deliveries,
  EmployeeGifts,
} from '../pages';
import {
  COMPANY_SETTINGS_PATH,
  USERS_PATH,
  OFFICES_PATH,
  ADMINISTRATION_PATH,
  LOGIN_PATH,
  OAUTH_CALLBACK_PATH,
  ROOT_PATH,
  EMPLOYEE_ACCOUNT_PATH,
  NEW_PATH,
  EDIT_OFFICE_PATH,
  EMPLOYEES_PATH,
  EMPLOYEE_PATH,
  EDIT_PATH,
  DEPENDANTS_PATH,
  DEPENDANT_PATH,
  DELIVERIES_PATH,
  DELIVERY_PATH,
  GIFTS_PATH,
  DETAILS_PATH,
  HISTORY_PATH,
  OVERVIEW_PATH,
  FORGOT_PASSWORD_PATH,
  ACCEPT_ADDRESS_PATH,
} from './paths';
import Layout from '../pages/shared/Layout';
import { AdministrationTabs } from '../pages/administration/AdministrationTabs/AdministrationTabs';
import { PublicCompanyOutlet } from './outlets/PublicCompanyOutlet';
import { PrivateCompanyOutlet } from './outlets/PrivateCompanyOutlet';
import { ShipmentDetails } from '../pages/deliveries/Shipment/ShipmentDetails';
import { ShipmentGifts } from '../pages/deliveries/Shipment/ShipmentGifts';
import { Overview } from '../pages/deliveries/Overview/Overview';
import { LoginPage } from '../pages/auth/LoginPage/LoginPage';
import { ForgotPasswordPage } from '../pages/auth/ForgotPasswordPage/ForgotPasswordPage';
import { ForgotPasswordNewPasswordPage } from '../pages/auth/ForgotPasswordPage/ForgotPasswordNewPasswordPage';
import { EmployeeAcceptGoogleAddressForm } from '../pages/employees/EmployeeForm/EmployeeAcceptGoogleAddressForm';

const AppRoutes = () => (
  <Routes>
    <Route element={<PublicCompanyOutlet />}>
      <Route element={<PrivateOutlet loginPath={LOGIN_PATH} />}>
        <Route element={<PrivateCompanyOutlet />}>
          <Route element={<Layout />}>
            <Route path={ROOT_PATH} element={<Navigate to={`${DELIVERIES_PATH}/${OVERVIEW_PATH}`} replace={true} />} />
            <Route path={EMPLOYEES_PATH}>
              <Route path='' element={<EmployeeManagementPage />} />
              <Route path={NEW_PATH} element={<EmployeeCreateForm />} />
              <Route path={EMPLOYEE_PATH}>
                <Route path={ACCEPT_ADDRESS_PATH} element={<EmployeeAcceptGoogleAddressForm />} />
                <Route path={EDIT_PATH} element={<EmployeeUpdateForm />} />
                <Route path={EMPLOYEE_ACCOUNT_PATH} element={<EmployeeAccount />} />
                <Route path={DEPENDANTS_PATH} element={<EmployeeDependants />} />
                <Route path={GIFTS_PATH} element={<EmployeeGifts />} />
                <Route path={DEPENDANTS_PATH}>
                  <Route path={NEW_PATH} element={<EmployeeDependantCreateForm />} />
                  <Route path={DEPENDANT_PATH}>
                    <Route path={EDIT_PATH} element={<EmployeeDependantUpdateForm />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path={ADMINISTRATION_PATH}>
              <Route path='' element={<Navigate to={COMPANY_SETTINGS_PATH} replace={true} />} />
              <Route element={<AdministrationTabs />}>
                <Route path={COMPANY_SETTINGS_PATH} element={<CompanySettings />} />
                <Route path={OFFICES_PATH}>
                  <Route path='' element={<Offices />} />
                  <Route path={NEW_PATH} element={<OfficeCreateForm />} />
                  <Route path={EDIT_OFFICE_PATH} element={<OfficeUpdateForm />} />
                </Route>
                <Route path={USERS_PATH}>
                  <Route path='' element={<Users />} />
                  <Route path={NEW_PATH} element={<UserCreateForm />} />
                </Route>
              </Route>
            </Route>
            <Route path={DELIVERIES_PATH}>
              <Route path='' element={<Navigate to={generatePath(OVERVIEW_PATH)} replace={true} />} />
              <Route path={HISTORY_PATH} element={<Deliveries />} />
              <Route path={OVERVIEW_PATH} element={<Overview />} />
              <Route path={DELIVERY_PATH}>
                <Route path={DETAILS_PATH} element={<ShipmentDetails />} />
                <Route path={GIFTS_PATH} element={<ShipmentGifts />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={LOGIN_PATH} element={<LoginPage />} />
      <Route path={FORGOT_PASSWORD_PATH}>
        <Route path='' element={<ForgotPasswordPage />} />
        <Route path={NEW_PATH} element={<ForgotPasswordNewPasswordPage />} />
      </Route>
      <Route path={OAUTH_CALLBACK_PATH} element={<OauthCallback afterOauthPath={ROOT_PATH} loginPath={LOGIN_PATH} />} />
    </Route>
  </Routes>
);

export default AppRoutes;
