import Joi from 'joi';

export const clearableField = (schema: Joi.Schema) => {
  return Joi.any()
    .when('.', {
      is: Joi.alternatives().try(Joi.string().trim().valid(''), Joi.valid(null)),
      then: Joi.any().custom(() => null),
      otherwise: schema,
    })
    .allow(null);
};
