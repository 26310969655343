import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ErrorPage,
  ExtendedFAB,
  LoadingPage,
  InfoCardInner,
  TMenuItemProps,
  TConfirmationModalProps,
  ConfirmationModal,
} from '@chocolate-soup-inc/cs-frontend-components';

import clsx from 'clsx';
import styles from './Offices.module.scss';
import { generatePath, useNavigate } from 'react-router-dom';
import { EDIT_OFFICE_PATH, NEW_PATH } from '../../../routes/paths';
import { TListOfficesQuery, useDeleteOfficeMutation } from '../../../generated/graphql';
import _ from 'lodash';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import { useQueryAllOffices } from '../../../entities/office/shared';

type TOffice = Exclude<TListOfficesQuery['listOffices']['items'][number], null | undefined>;

export const CompanyOffices = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const { id: companyId } = usePrivateCompanyContext();

  const { data, error, loading } = useQueryAllOffices({
    companyId,
  });

  const offices: TOffice[] = useMemo(() => {
    return _.compact(data?.listOffices?.items || []);
  }, [data]);

  const onEditClick = useCallback(
    (office: TOffice) => {
      navigate(
        generatePath(EDIT_OFFICE_PATH, {
          officeId: office.id,
        }),
      );
    },
    [navigate],
  );

  const [deleteOffice, { loading: deleteLoading }] = useDeleteOfficeMutation();

  const onActualDelete = useCallback(
    (office: TOffice) => {
      deleteOffice({
        variables: {
          id: office.id,
          companyId: office.companyId,
          version: office._version,
        },
      })
        .then()
        .catch((error) => {
          console.error(serializeError(error));
          toast.error(error.message);
        })
        .then(() => {
          setConfirmationProps(undefined);
        });
    },
    [deleteOffice],
  );

  const onDeleteClick = useCallback(
    (office: TOffice) => {
      setConfirmationProps({
        headline: 'Delete office?',
        supportingText: `Are you sure you want to delete the office ${office?.name}?`,
        confirmLabel: 'Delete',
        onConfirmClick: () => onActualDelete(office),
      });
    },
    [onActualDelete],
  );

  const options = useCallback(
    (office: TOffice): TMenuItemProps[] => {
      return [
        {
          disabled: deleteLoading,
          label: 'Edit Office',
          onClick: () => onEditClick(office),
          type: 'text',
        },
        {
          disabled: deleteLoading,
          label: 'Remove Office',
          loading: deleteLoading,
          onClick: () => onDeleteClick(office),
          type: 'text',
        },
      ];
    },
    [deleteLoading, onDeleteClick, onEditClick],
  );

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={deleteLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <div className={clsx(styles.companyOffices, 'grid grid-cols-12')} ref={ref}>
        <div className='col-span-8 col-start-3'>
          <ExtendedFAB
            className={styles.addNew}
            leadingIcon='add'
            label='Add Office'
            onClick={() => navigate(NEW_PATH)}
            variant='surface'
          />
          {offices.map((office: TOffice) => {
            return (
              <InfoCardInner<TOffice>
                key={office.id}
                sections={[
                  {
                    title: office.name,
                    optionsMenu: options(office),
                    columns: [
                      {
                        label: 'Contact name',
                        render: (data) => data?.contact?.name,
                      },
                      {
                        label: 'Contact email',
                        render: (data) => data?.contact?.email,
                      },
                      {
                        label: 'Contact phone',
                        render: (data) => data?.contact?.phoneNumber,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        label: 'Address line 1',
                        render: (data) => data?.address?.address1,
                      },
                      {
                        label: 'Address line 2',
                        render: (data) => data?.address?.address2,
                      },
                      {
                        label: 'City',
                        render: (data) => data?.address?.city,
                      },
                      {
                        label: 'State',
                        render: (data) => data?.address?.state,
                      },
                      {
                        label: 'Country',
                        render: (data) => data?.address?.country,
                      },
                      {
                        label: 'Zip Code',
                        render: (data) => data?.address?.zipCode,
                      },
                    ],
                  },
                ]}
                data={office}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
