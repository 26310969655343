import { Chip, IconButton, Modal, readableDate, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { ReactNode, useCallback } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { TGetEmployee } from '../../../entities/employee/shared';
import { TDeliveryMethod } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { EMPLOYEES_PATH } from '../../../routes/paths';
import { EmployeeTabs } from './EmployeeTabs';

import styles from './EmployeeTabs.module.scss';

type TEmployeeTabsModalProps = {
  children: ReactNode;
  employee?: TGetEmployee;
  headline?: string;
};

export const EmployeeTabsModal = (props: TEmployeeTabsModalProps) => {
  const { children, employee, headline } = props;
  const { state } = useLocation();
  const { backTo } = state || {};

  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    if (backTo) {
      navigate(backTo);
    } else {
      navigate(generatePath(EMPLOYEES_PATH));
    }
  }, [navigate, backTo]);

  const { deliveryMethod, employeeBirthdayActivated } = usePrivateCompanyContext();

  return (
    <Modal contentClassName={styles.modal} closeModal={closeModal} size='large'>
      <div className={styles.employeeTabsModal}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleContainer}>
            {headline && <h1 className={styles.employeeTabsTitle}>{headline}</h1>}
            <div className={styles.chipsContainer}>
              {(_.isEmpty(employee?.address) || employee?.address.missingInfo) &&
                deliveryMethod === TDeliveryMethod.Home && (
                  <Tooltip message='All address fields but address 2 are required for gifts to be sent.'>
                    <Chip
                      className={styles.errorChip}
                      label='Missing address'
                      readonly={true}
                      selected={true}
                      variant='suggestion'
                    />
                  </Tooltip>
                )}
              {employee?.deletedAt && (
                <Tooltip
                  message={`Deleted on ${readableDate(employee.deletedAt, 'MMM do, yyyy')}`}
                  placement={'top-start'}
                >
                  <Chip
                    className={styles.errorChip}
                    label='Deleted'
                    readonly={true}
                    selected={true}
                    variant='suggestion'
                  />
                </Tooltip>
              )}
              {deliveryMethod !== TDeliveryMethod.Office && employee?.address?.missingInfo && (
                <Chip
                  className={styles.errorChip}
                  label='Address incomplete'
                  readonly={true}
                  selected={true}
                  variant='suggestion'
                />
              )}
              {(employee?.hireDate == null || employee?.hireDate === '') && (
                <Chip
                  className={styles.errorChip}
                  label='Missing hire date'
                  readonly={true}
                  selected={true}
                  variant='suggestion'
                />
              )}
              {(employee?.birthDate == null || employee?.birthDate === '') && employeeBirthdayActivated && (
                <Chip
                  className={styles.errorChip}
                  label='Missing birth date'
                  readonly={true}
                  selected={true}
                  variant='suggestion'
                />
              )}
              {employee?.address?.alert != null && (
                <Chip
                  className={styles.alertChip}
                  hideSelectedIcon={true}
                  label='Address not validated'
                  readonly={true}
                  selected={true}
                  variant='filter'
                />
              )}
            </div>
          </div>
          <IconButton icon='close' onClick={closeModal} variant='standard' />
        </div>

        <div className={styles.header}>
          <EmployeeTabs />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </Modal>
  );
};
