import { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components';

export const getOfficeFormFields = () => {
  const formFields: TControlledFormComponentProps[] = [
    {
      name: 'name',
      label: 'Office Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      title: 'Address',
      type: 'section',
    },
    {
      name: 'address.address1',
      label: 'Address Line 1',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.address2',
      label: 'Address Line 2',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.city',
      label: 'City',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.state',
      label: 'Province / State',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.zipCode',
      label: 'Zip Code',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'address.country',
      label: 'Country',
      type: 'countrySelect',
      inputOptions: {
        variant: 'outlined',
        autoComplete: 'off',
        includeEmptyOption: true,
      },
    },
    {
      title: 'Contact',
      type: 'section',
    },
    {
      name: 'contact.name',
      label: 'Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'contact.email',
      label: 'Email',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'contact.phoneNumber',
      label: 'Phone Number',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
  ];

  return formFields;
};
