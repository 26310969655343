import Joi from 'joi';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const phoneNumberExtension = (joi: Joi.Root): Joi.Extension => ({
  type: 'phoneNumber',
  base: joi.string(),
  messages: {
    'phoneNumber.invalid': '{{#label}} is not a valid phone number.',
  },
  prepare: (value: string | number | null | undefined) => {
    const cleanNumber = value ? value.toString().replace(/[^+\d]/g, '') : null;
    if (cleanNumber === '' || cleanNumber == null) return { value: null };
    try {
      return {
        value: phoneUtil.format(phoneUtil.parseAndKeepRawInput(cleanNumber), PhoneNumberFormat.INTERNATIONAL),
      };
    } catch (e) {
      return {
        value: cleanNumber,
      };
    }
  },
});
