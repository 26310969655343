import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { CommonButton } from '@chocolate-soup-inc/cs-frontend-components';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.header}>
      Chocolate Soup
      <CommonButton className={styles.headerButton} onClick={() => Auth.signOut()} label='Logout' variant='text' />
    </div>
  );
};

export default Header;
