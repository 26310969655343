import { useCallback, useMemo, useState } from 'react';
import {
  CommonButton,
  ConfirmationModal,
  ErrorPage,
  IconButton,
  InfoCardInner,
  LoadingPage,
  Modal,
  ModalForm,
  readableDate,
  Switch,
  TConfirmationModalProps,
  TInfoCardSectionColumnProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { TInfoCardSectionColumnInnerProps } from '@chocolate-soup-inc/cs-frontend-components/lib/esm/components/InfoCard/types/InfoCardSection';
import styles from './EmployeeAccount.module.scss';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  TGetEmployeeQuery,
  TWorkAnniversaryGiftVariations,
  usePauseEmployeeGiftsMutation,
  useSkipAddressAlertMutation,
  useUnpauseEmployeeGiftsMutation,
  useUpdateEmployeeMutation,
} from '../../../generated/graphql';
import { EDIT_PATH, EMPLOYEE_PATH, EMPLOYEES_PATH } from '../../../routes/paths';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useFragmentOrFetchEmployee } from '../../../entities/employee/shared';
import { EmployeeTabsModal } from '../EmployeeTabs/EmployeeTabsModal';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { customJoiDate } from '../../../shared/utils';
import clsx from 'clsx';

type TEmployee = Exclude<TGetEmployeeQuery['getEmployee'], undefined | null>;

export const EmployeeAccount = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const {
    id: companyId,
    employeeBirthdayActivated,
    employeeWorkAnniversaryActivated,
    hasCustomOnboardBrick,
    workAnniversaryGiftVariation,
  } = usePrivateCompanyContext();

  const {
    data: employee,
    error,
    loading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId,
  });

  const title = useMemo(() => {
    const fullName = employee?.fullName;
    if (fullName) return fullName;
    else return 'Employee Account';
  }, [employee?.fullName]);

  const onEditClick = useCallback(() => {
    navigate(
      generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EDIT_PATH}`, {
        employeeId: employeeId as string,
      }),
    );
  }, [employeeId, navigate]);

  const [updateEmployee, { loading: deleteLoading }] = useUpdateEmployeeMutation();

  const onActualDelete = useCallback(() => {
    const input = employee?.deletedAt ? { deletedAt: null } : { deletedAt: new Date() };
    return updateEmployee({
      variables: {
        id: employee?.id as string,
        companyId: employee?.companyId as string,
        version: employee?._version as number,
        input,
      },
    })
      .then(() => {
        navigate(generatePath(EMPLOYEES_PATH));
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error(error.message);
      })
      .then(() => {
        setConfirmationProps(undefined);
      });
  }, [updateEmployee, employee?._version, employee?.companyId, employee?.id, employee?.deletedAt, navigate]);

  const onDeleteClick = useCallback(() => {
    employee?.deletedAt
      ? setConfirmationProps({
          headline: 'Restore employee?',
          supportingText: `Are you sure you want to RESTORE the employee ${employee?.fullName}?`,
          confirmLabel: 'Restore',
          onConfirmClick: onActualDelete,
        })
      : setConfirmationProps({
          headline: 'Delete employee?',
          supportingText: `Are you sure you want to DELETE the employee ${employee?.fullName}?`,
          confirmLabel: 'Delete',
          onConfirmClick: onActualDelete,
        });
  }, [employee?.fullName, employee?.deletedAt, onActualDelete]);

  const isPauseEnabled = useMemo(() => {
    return !!employee?.isPauseEnabled;
  }, [employee?.isPauseEnabled]);

  const [pauseModalOpen, setPauseModalOpen] = useState<boolean>(false);

  const [pauseEmployeeGifts, { loading: pauseLoading }] = usePauseEmployeeGiftsMutation();

  const [unpauseEmployeeGifts, { loading: unpauseLoading }] = useUnpauseEmployeeGiftsMutation();

  const onActualPause = useCallback(
    (data: any) => {
      if (employee?.id && employee?.companyId && employee?._version) {
        return pauseEmployeeGifts({
          variables: {
            id: employee.id,
            companyId: employee.companyId,
            version: employee._version,
            fromDate: data.fromDate,
            toDate: data.toDate,
          },
        })
          .then(() => {
            toast.success('Employee gifts successfully paused.');
            setPauseModalOpen(false);
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error pausing the employee gifts.');
          });
      }
    },
    [employee?._version, employee?.companyId, employee?.id, pauseEmployeeGifts],
  );

  const onActualResume = useCallback(() => {
    if (employee?.id && employee?.companyId && employee?._version) {
      return unpauseEmployeeGifts({
        variables: {
          id: employee.id,
          companyId: employee.companyId,
          version: employee._version,
        },
      })
        .then(() => {
          toast.success('Employee gifts successfully unpaused.');
          setConfirmationProps({});
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error unpausing the employee gifts.');
        });
    }
  }, [employee?._version, employee?.companyId, employee?.id, unpauseEmployeeGifts]);

  const onPauseChange = useCallback(() => {
    if (isPauseEnabled) {
      setConfirmationProps({
        headline: 'Resume employee gifts?',
        supportingText: `Are you sure you want to resume the employee ${employee?.fullName} gifts? By resugreaterg the employee gifts all gifts that were not sent during the last pause period will be sent.`,
        confirmLabel: 'Resume',
        onConfirmClick: onActualResume,
      });
    } else {
      setPauseModalOpen(true);
    }
  }, [employee?.fullName, isPauseEnabled, onActualResume]);

  const pauseSchema = useMemo(() => {
    return customJoiDate.object({
      fromDate: customJoiDate.customJoiDate().pastInvalid().required().AWSDate(),
      toDate: customJoiDate.customJoiDate().pastInvalid().greater(Joi.ref('fromDate')).AWSDate().messages({
        'date.greater': '"To date" must be greater than the "from date".',
      }),
    });
  }, []);

  const pausePeriodText = useMemo(() => {
    if (employee?.pausePeriod != null && !_.isEmpty(employee.pausePeriod)) {
      const { fromDate, toDate } = employee.pausePeriod;

      let text: string | undefined;
      if (fromDate != null) {
        text = `From ${readableDate(fromDate)}`;
      }

      if (toDate != null) {
        text = `${text}${text == null ? 'To' : ' to'} ${readableDate(toDate)}`;
      }

      return text;
    }
  }, [employee?.pausePeriod]);

  const defaultValues = useMemo(() => {
    return {
      fromDate: new Date(),
      toDate: undefined,
    };
  }, []);

  const topComponent = useMemo(() => {
    return (
      <div className={styles.topComponent}>
        <div className={styles.topTitleContainer}>
          <h2 className={styles.topTitle}>Information</h2>
          <div className={styles.topIcons}>
            <Tooltip message='Edit Employee Information'>
              <IconButton icon='edit' iconProps={{ weight: 300 }} onClick={onEditClick} variant='standard' />
            </Tooltip>
            {employee?.deletedAt ? (
              <Tooltip message='Restore Employee'>
                <IconButton icon='sync' iconProps={{ weight: 300 }} onClick={onDeleteClick} variant='standard' />
              </Tooltip>
            ) : (
              <Tooltip message='Remove Employee'>
                <IconButton icon='delete' iconProps={{ weight: 300 }} onClick={onDeleteClick} variant='standard' />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  }, [onDeleteClick, onEditClick, employee?.deletedAt]);

  const [skipAddressAlert, { loading: skipAddressAlertLoading }] = useSkipAddressAlertMutation();

  const onActualMarkAddressAsVerified = useCallback(() => {
    if (employee?.address) {
      return skipAddressAlert({
        variables: {
          id: employee.address.id,
          companyId: employee.address.companyId,
          version: employee.address._version,
        },
      })
        .then(() => {
          toast.success('Address marked as verified successfully.');
          setConfirmationProps({});
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('Could not mark address as verified.');
        });
    }
  }, [employee?.address, skipAddressAlert]);

  const onMarkAddressAsVerifiedClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Mark address as verified?',
      supportingText: `Are you sure you want to mark the employee ${employee?.fullName} address as verified? Our system could not validate it against Google Maps.`,
      confirmLabel: 'Mark as verified',
      onConfirmClick: onActualMarkAddressAsVerified,
    });
  }, [employee?.fullName, onActualMarkAddressAsVerified]);

  const addressTopComponent = useMemo(() => {
    if (employee?.address?.alert == null) return null;

    return (
      <div className={clsx(styles.topComponent, styles.alertComponent)}>
        <div className={styles.topTitleContainer}>
          <p className={styles.alertTitle}>Address could not validated on Google.</p>
          <div className={clsx(styles.topIcons, styles.alertTopIcons)}>
            <CommonButton
              label='Mark as verified'
              loading={skipAddressAlertLoading}
              onClick={onMarkAddressAsVerifiedClick}
              trailingIcon='check_circle'
              variant='elevated'
            />
          </div>
        </div>
      </div>
    );
  }, [employee?.address?.alert, onMarkAddressAsVerifiedClick, skipAddressAlertLoading]);

  const topColumns = useMemo(() => {
    const cols: TInfoCardSectionColumnProps<TEmployee>[] = [
      {
        label: 'Employee ID',
        accessor: 'externalId',
      },
      {
        label: 'First Name',
        accessor: 'firstName',
      },
      {
        label: 'Last Name',
        accessor: 'lastName',
      },
      {
        label: 'Hire Date',
        render: (data) => readableDate(data?.hireDate),
      },
    ];

    if (hasCustomOnboardBrick) {
      cols.push({
        label: 'Custom Onboard Brick',
        accessor: 'customOnboardBrick',
      });
    }

    return cols;
  }, [hasCustomOnboardBrick]);

  if (loading || !employee) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error) return <ErrorPage error={error} />;

  const employeeInformationColumns: TInfoCardSectionColumnInnerProps<TEmployee>[] = [];
  employeeInformationColumns.push(
    {
      label: 'Office',
      render: (data?) => data?.office?.name,
    },
    {
      label: 'Preferred First Name',
      accessor: 'preferredFirstName',
    },
  );
  if (employeeBirthdayActivated) {
    employeeInformationColumns.push({
      label: 'Date of Birth',
      render: (data) => readableDate(data?.birthDate),
    });
  }
  employeeInformationColumns.push(
    {
      label: 'Email',
      accessor: 'email',
    },
    {
      label: 'Phone Number',
      accessor: 'phoneNumber',
    },
  );

  const employeeDonationColumns: TInfoCardSectionColumnInnerProps<TEmployee>[] = [];
  if (employeeBirthdayActivated) {
    employeeDonationColumns.push({
      label: 'Donate Birthday Gifts to Charity',
      accessor: 'donateBirthdayGift',
      render: (data) => (data?.donateBirthdayGift ? 'yes' : 'no'),
    });
  }
  if (employeeWorkAnniversaryActivated && workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Deluxe) {
    employeeDonationColumns.push({
      label: 'Donate Work Anniversary Gifts to Charity',
      accessor: 'donateWorkAnniversaryGift',
      render: (data) => (data?.donateWorkAnniversaryGift ? 'yes' : 'no'),
    });
  }
  const employeeDonationSection = employeeDonationColumns.length ? [{ columns: employeeDonationColumns }] : [];

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={deleteLoading || pauseLoading || unpauseLoading || unpauseLoading || skipAddressAlertLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      {pauseModalOpen && (
        <ModalForm
          closeModal={() => setPauseModalOpen(false)}
          onCancelClick={() => setPauseModalOpen(false)}
          confirmLabel='Pause'
          headline='Pause employee gifts?'
          supportingText={`Are you sure you want to pause the employee ${employee?.fullName} gifts? By pausing the employee gifts you will still be charged his subscription and his gifting will be paused. Once he gets unpaused, all the gifts that should have been sent during the pause period will be sent. This change might take up to 4 weeks to take effect.`}
          controlledFormProps={{
            fields: [
              {
                label: 'From Date',
                name: 'fromDate',
                type: 'datePicker',
                inputOptions: {
                  disablePast: true,
                },
              },
              {
                label: 'To Date',
                name: 'toDate',
                type: 'datePicker',
                inputOptions: {
                  disablePast: true,
                  supportingText: 'If no date is provided, the employee gifts will be paused until manually unpaused.',
                },
              },
            ],
            formProps: {
              defaultValues,
              resolver: joiResolver(pauseSchema, {
                convert: true,
                abortEarly: false,
                stripUnknown: false,
              }),
            },
            onValidSubmit: onActualPause,
          }}
        />
      )}
      <EmployeeTabsModal employee={employee} headline={title}>
        <div className={styles.employeeAccount}>
          <Switch
            className={styles.switch}
            name='isPauseEnabled'
            onChange={onPauseChange}
            label='Pause Shipments'
            value={isPauseEnabled}
            supportingText={pausePeriodText}
          />
          <InfoCardInner<TEmployee>
            data={employee}
            sections={[
              {
                // title: 'Information',
                topComponent: () => topComponent,
                columns: topColumns,
                // optionsMenu: options,
              },
              {
                columns: employeeInformationColumns,
              },
              {
                columns: [
                  {
                    label: 'Allergies & Dietary Restrictions',
                    accessor: 'foodPreferences',
                  },
                ],
              },
              {
                columns: [
                  {
                    label: 'T-Shirt Size',
                    accessor: 'tShirtSize',
                  },
                  {
                    label: 'Sweater Size',
                    accessor: 'sweaterSize',
                  },
                ],
              },
              {
                topComponent: () => addressTopComponent,
                columns: [
                  {
                    label: 'Address Line 1',
                    render: (data) => data?.address?.address1,
                  },
                  {
                    label: 'Address Line 2',
                    render: (data) => data?.address?.address2,
                  },
                  {
                    label: 'City',
                    render: (data) => data?.address?.city,
                  },
                  {
                    label: 'State',
                    render: (data) => data?.address?.state,
                  },
                  {
                    label: 'Country',
                    render: (data) => data?.address?.country,
                  },
                  {
                    label: 'Zip Code',
                    render: (data) => data?.address?.zipCode,
                  },
                ],
              },
              ...employeeDonationSection,
            ]}
          />
        </div>
      </EmployeeTabsModal>
    </>
  );
};
